// import './bootstrap';

import '@/icons/bwi/all-bwi.css'

import '@fontsource/dm-sans/latin-400-italic.css'
import '@fontsource/dm-sans/latin-400.css'
import '@fontsource/dm-sans/latin-500.css'
import '@fontsource/dm-sans/latin-700.css'

import '../css/app.css'

import { createInertiaApp } from '@inertiajs/react'
import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import ErrorBoundary from '@/components/error-boundary.js'
import NinetailedProviderWrapper from '@/components/ninetailed-provider.tsx'
import { mapNtContentfulAudiences, mapNtContentfulExperiences } from '@/utils/ninetailed.ts'

import * as Sentry from '@sentry/react'

const renderApp = (el: HTMLElement, App: any, props: any) => {
  return (
    <ErrorBoundary>
      <NinetailedProviderWrapper
        allExperiences={mapNtContentfulExperiences(props.initialPage.props.ntExperiences)}
        allAudiences={mapNtContentfulAudiences(props.initialPage.props.ntAudiences)}
        ninetailedClientId={props.initialPage.props.env.PUBLIC_NINETAILED_CLIENT_ID}
        ninetailedEnv={props.initialPage.props.env.PUBLIC_NINETAILED_CLIENT_ENV}
      >
        <App {...props} />
      </NinetailedProviderWrapper>
    </ErrorBoundary>
  )
}

createInertiaApp({

  resolve: (name: string) => resolvePageComponent(`./pages/${name}.tsx`, import.meta.glob('./pages/**/*.tsx')),
  setup({ el, App, props }: { el: HTMLElement; App: any; props: any }) {
    const app = renderApp(el, App, props)

    if (props.initialPage.props.env.PUBLIC_SENTRY_DSN) {
      Sentry.init({
        dsn: props.initialPage.props.env.PUBLIC_SENTRY_DSN,
        integrations: [
          Sentry.replayIntegration(),
        ],
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        maxBreadcrumbs: 50,
        debug: props.initialPage.props.env.PUBLIC_SENTRY_DEBUG==='true',
      })
    }

    if (import.meta.env.DEV) {
      createRoot(el).render(app)
    } else {
      hydrateRoot(el, app)
    }
  },
})
